import { Router } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(public authService: AuthService, private router: Router, private toastr: ToastrService) {
    document.body.style.backgroundColor ="rgb(19, 34, 86)";
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    document.body.style.backgroundColor ="rgb(255, 255, 255)";
  }

  public message = {
    email: '',
    password: '',
    remember_me: ''
  }

  onSignIn(loginForm: NgForm): void {
    this.message.password = '';
    if (loginForm.value.remember_me === '' || loginForm.value.remember_me === null) {
      loginForm.value.remember_me = false;
    }
    this.authService.loginUser(loginForm.value).subscribe((data: any) => {
      this.authService.setToken(data.access_token);
      if (this.authService.getToken() !== '') {
        this.router.navigate(['cotizacion']);
        loginForm.resetForm();
        this.toastr.success('Bienvenido al sistema', 'Acceso Correcto');
      }
    }, (error: any) => {
      if(error.error.errors != null){
        this.message = error.error.errors;
      }
      if(this.message.email == null){
        this.message.email = '';
      }
      if(this.message.password == null){
        this.message.password = '';
      }
      if (error.status === 401) {
        this.toastr.error('Usuario o Contraseña Incorrecto', 'Acceso Denegado');
        this.message.email = '';
        this.message.password = '';
      }  else if (error.status === 422){
        this.toastr.warning('Falta campos por rellenar', 'Acceso Denegado');
      } else {
        this.toastr.error('No se pudo establecer conexion con el servidor', 'Error de Sistema');
      }

    });

  }

}
