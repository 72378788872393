import { DataApiService } from './../../services/data-api.service';
import { DescuentoService } from './../../services/descuento.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { IntermediarioService } from './../../services/intermediario.service';
import { Intermediario } from './../../interfaces/intermediario';
import { LineaService } from './../../services/linea.service';
import { MarcaService } from './../../services/marca.service';
import { Marca } from './../../interfaces/marca';
import { TipoVehiculo } from './../../interfaces/tipo-vehiculo';
import { TipoVehiculoService } from './../../services/tipo-vehiculo.service';
import { CotizacionService } from './../../services/cotizacion.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Linea } from 'src/app/interfaces/linea';
import { ToastrService } from 'ngx-toastr';
import { Descuento } from 'src/app/interfaces/descuento';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent implements OnInit {

  tipoVehiculos: TipoVehiculo[];
  marcas: Marca[];
  lineas: Linea[];
  intermediarios: Intermediario[];
  descuentos: Descuento[];
  usuarios: any[];
  miusuario = {
    description: '',
    name: '',
    rol: '',
    role_id: 0,
    user_id: 0
  };

  descuentoValidado = 0;

  descuentoEspecial = 0;
  descuentoAutorizacionEspecial = 0;
  mostrarCamposDescuento = false;

  usuariosCompleto: any[];

  public mostrarBoton: boolean;

  planesSeleccionados:number[];

  dataCotizaciones: any = [];

  dataPagos: any = [];

  mostrarFormulario: boolean;

  limite: number;

  estadoDescuento: boolean;

  limiteAnios: number;

  limiteAntiguedad: number;

  habilitarDescuento: boolean;

  habilitarValPactado: boolean;

  //Controla los valores en html para el combo valor_pactado
 // @ViewChild('valor_pactado', { static: true }) valor_pactadoElement: ElementRef;

  public cotizacion = {
    validity_type_id: 1,
    intermediary_id: '',
    tipo_vehiculo: 0,
    marca: 0,
    linea: '',
    lineaNombre: '',
    modelo: 0,
    suma_asegurada: 0,
    user_id: '',
    nit: '',
    nombre: '',
    apellio: '',
    telefono: '',
    email: '',
    uber: 0,
    menores: 0,
    cobertura: 1,
    numero_ocupantes: 0,
    valor_pactado: 1,
    descuento: 0,
    recargo: 0,
    gps: 0,
    estado: '',
    observaciones: '',
    dirigido: 0,
    descuento_id: 0
  };

  public message = {
    validity_type_id: '',
    intermediary_id: '',
    tipo_vehiculo: '',
    marca: '',
    linea: '',
    lineaNombre: '',
    modelo: '',
    suma_asegurada: '',
    user_id: 0,
    nit: '',
    nombre: '',
    apellio: '',
    telefono: '',
    email: '',
    uber: '',
    menores: '',
    cobertura: '',
    numero_ocupantes: '',
    valor_pactado: '',
    descuento: '',
    recargo: '',
    gps: '',
    estado: '',
    dirigido: '',
    solicitud: ''
  }

  public step: number;
  public anio: number;

  constructor(private tipoVehiculoService: TipoVehiculoService, private marcaService: MarcaService, private lineaService: LineaService, private intermediarioService: IntermediarioService, private cotizacionService: CotizacionService,private descuentoService: DescuentoService, private toastr: ToastrService, private router: Router, private dataApiService: DataApiService) { }

  ngOnInit() {
    this.step = 1;
    this.cotizacionService.getLimiteAnios().subscribe((data: any) => {
      this.limiteAnios = parseInt(data.data.valor, 10);
    });
    this.cotizacionService.getLimiteAntiguedad().subscribe((data: any) => {
      this.limiteAntiguedad = parseInt(data.data[0].valor, 10);
    });
    this.mostrarFormulario = false;
    this.mostrarBoton = true;
    this.planesSeleccionados = [0,0,0];

    this.getListTipoVehiculos();
    this.getListMarcas();
    /* this.getListIntermediarios(); */
    this.getListDescuentos();
    this.anio = new Date().getFullYear();

    this.cotizacionService.getUser().subscribe((data: any) => {
      this.cotizacion.user_id = data.id;

      this.cotizacionService.getMyUser(data.id).subscribe((data: any) => {
        this.miusuario = data;
        if (this.miusuario.role_id == 7 || this.miusuario.role_id == 8 || this.miusuario.role_id == 9) {
          this.cotizacion.intermediary_id = this.miusuario.name;
        }
      });

      this.cargarDescuento(Number(this.cotizacion.user_id));

      this.cotizacionService.getUsers().subscribe((data: any) => {
        this.usuarios = data.data;
        this.usuariosCompleto = data.data;

        this.usuarios = this.filterFunction2(this.usuariosCompleto);
        /* this.removeItemFromArr(this.usuarios, this.cotizacion.user_id); */
      });
    });

    this.estadoDescuento = false;



  }

  validarPantalla1() {
    if (this.step === 1) {
      if ( this.cotizacion.nombre === '') {
        this.message.nombre = 'Ingrese un nombre válido';
      } else {
        this.message.nombre = '';
      }

      if ( this.cotizacion.nit === '') {
        /* this.message.nit = 'Ingrese un nit válido'; */
        this.message.nit = '';
      } else if ( !this.nitIsValid(this.cotizacion.nit)) {
        this.message.nit = 'El nit no es válido';
      } else {
        this.message.nit = '';
      }

      if ( this.cotizacion.email === '') {
        /* this.message.email = 'Ingrese un email válido'; */
        this.message.email = '';
      } else if ( !this.emailIsValid(this.cotizacion.email)) {
        this.message.email = 'El correo no es válido';
      } else {
        this.message.email = '';
      }

      if ( this.cotizacion.telefono === '') {
        this.message.telefono = 'Ingrese un numero de teléfono válido';
      } else if(this.cotizacion.telefono.length != 8) {
        this.message.telefono = 'El número no es válido';
      }else if (this.telefonoIsValid(this.cotizacion.telefono)) {
        this.message.telefono = 'El número no es válido';
      } else {
        this.message.telefono = '';
      }

      /* if (valor>0 && val){
        this.step = valor;
      } else { */
      if ( this.message.nombre === '' && this.message.nit === '' && this.message.telefono === '') {
        this.step = this.step + 1;
      }
      /* } */

    }
  }

  validarPantalla2() {
    if (this.step === 2) {
      if ( this.cotizacion.tipo_vehiculo === 0) {
        this.message.tipo_vehiculo = 'Seleccione un tipo vehiculo';
      } else {
        this.message.tipo_vehiculo = '';
      }

      if ( this.cotizacion.modelo === 0) {
        this.message.modelo = 'Ingrese un modelo válido';
      } else if ( this.cotizacion.modelo < this.anio - this.limiteAnios || this.cotizacion.modelo > this.anio + 1) {
        this.message.modelo = 'El modelo esta fuera de rango soportado desde '  + (this.anio - this.limiteAnios) + ' hasta ' + (this.anio + 1);
      } else {
        this.message.modelo = '';
      }

      if ( this.cotizacion.marca === 0) {
        this.message.marca = 'Seleccione una marca';
      } else {
        this.message.marca = '';
      }

      if (this.cotizacion.linea === '') {
        this.message.linea = 'Seleccione una linea';
      } else {
        this.message.linea = '';
      }

      if ( this.cotizacion.suma_asegurada === 0) {
        this.message.suma_asegurada = 'Ingrese una suma asegurada válida';
      } else if (this.cotizacion.suma_asegurada < 1) {
        this.message.suma_asegurada = 'Ingrese una suma asegurada válida';
      } else if ( !this.validarNumero(this.cotizacion.suma_asegurada) ) {
        this.message.suma_asegurada = 'El número no permite comas o valor inválido';
      } else {
        this.message.suma_asegurada = '';
      }

      /*
        //eaquino 04/05/2022
        //Recarga el valor del descuento autorizado segun usuario y suma asegurada
      */
      this.cargarDescSuma(Number(this.cotizacion.user_id));

      if ( this.cotizacion.numero_ocupantes === 0) {
        this.message.numero_ocupantes = 'El valor es incorrecto';
      } else if ( this.cotizacion.tipo_vehiculo == 8 && (this.cotizacion.numero_ocupantes < 10 || this.cotizacion.numero_ocupantes > 20)) {
        this.message.numero_ocupantes = 'El valor debe esta en un rando de 10 a 20';
        this.cotizacion.tipo_vehiculo = 10;
      } else {
        this.message.numero_ocupantes = '';
      }
      /* if (valor>0 && val){
        this.step = valor;
      } else { */
      if(this.message.tipo_vehiculo=='' && this.message.modelo=='' && this.message.marca == '' && this.message.linea == '' && this.message.suma_asegurada == '' && this.message.numero_ocupantes == '') {
        this.step = this.step + 1;
      }
      /* } */

    }
  }

  validarPantalla3() {
    if (this.step === 3) {
      if ( this.cotizacion.uber < 0 ) {
        this.message.uber = 'Seleccione una opcion';
      } else {
        this.message.uber = '';
      }

      if ( this.cotizacion.gps < 0) {
        this.message.gps = 'Seleccione una opcion';
      } else {
        this.message.gps = '';
      }

      if ( this.cotizacion.menores < 0) {
        this.message.menores = 'Seleccione una opcion';
      } else {
        this.message.menores = '';
      }
/*
      if ( this.cotizacion.valor_pactado < 0 ) {
        this.message.valor_pactado = 'Seleccione una opcion';
      } else {
        this.message.valor_pactado = '';
      }
*/
      if ( this.cotizacion.intermediary_id === '') {
        this.message.intermediary_id = 'Escriba un intermediario';
      } else {
        this.message.intermediary_id = '';
      }

      if ( this.cotizacion.descuento < 0) {
        this.message.descuento = 'Ingrese un valor entre 0-' + this.limite;
      } else if (this.cotizacion.descuento < 0 || this.cotizacion.descuento > this.limite) {
        let opcion = false;
        if (this.mostrarCamposDescuento == false) {
          opcion = confirm("¿Desea realizar una solicitud de descuento?");
        }
        if (opcion) {
          this.descuentoEspecial = 0;
          this.descuentoAutorizacionEspecial = this.cotizacion.descuento;
          this.mostrarCamposDescuento = true;
          this.message.descuento = '';
        } else {
          if (!this.mostrarCamposDescuento) {
            this.message.descuento = 'Ingrese un valor entre 0-' + this.limite;
          }
        }
      } else {
        this.message.descuento = '';
      }

      if ( this.cotizacion.recargo < 0) {
        this.message.recargo = 'Ingrese un valor numerico';
      /*
      } else if (this.cotizacion.recargo < 0 || this.cotizacion.recargo > 5) {
        this.message.recargo = 'Ingrese un entre 0-5';
      */
      } else {
        this.message.recargo = '';
      }

      /* if (valor>0 && val){
        this.step = valor;
      } else { */
      if ( this.message.uber == '' && this.message.gps == '' && this.message.menores=='' && this.message.intermediary_id=='' && this.message.descuento=='' && this.message.recargo=='') {
        this.descuentoValidado = 0;
        if (this.cotizacion.descuento > this.limite && this.mostrarCamposDescuento == true) {
          if ((this.cotizacion.descuento > this.limite && this.limite > 0 && this.cotizacion.dirigido > 0) || (this.cotizacion.descuento <= this.limite && this.cotizacion.dirigido == 0) || (this.cotizacion.descuento > this.limite && this.miusuario.role_id == 7 && this.cotizacion.dirigido > 0)) {
            if (this.cotizacion.descuento > this.filterFunctionValorDescuento(this.usuarios,this.cotizacion.dirigido)) {
              this.toastr.warning('El descuento es mayor a la capacidad del usuario seleccionado','No válido');
            } else {
              if ((this.cotizacion.descuento == 0 || this.cotizacion.descuento.toString() == '') && !this.mostrarCamposDescuento) {
                this.toastr.warning("El descuento que desea solicitar debe ser mayor a 0","Descuento");
                return;
              } else if (this.cotizacion.descuento == 0 || this.cotizacion.descuento.toString() == '') {
                this.cotizacion.descuento = 0;
                this.message.dirigido = '';
                this.message.solicitud = '';

              }
              this.descuentoValidado = 2;
              this.step = this.step + 1;
            }

          } else if ((this.cotizacion.descuento > this.limite && this.limite > 0 && this.cotizacion.dirigido == 0) || this.cotizacion.descuento > this.limite && this.miusuario.role_id == 7 && this.cotizacion.dirigido == 0) {
            this.message.dirigido = 'Debe indicar a quien solicitar';
          } else if (this.cotizacion.dirigido != 0 && this.cotizacion.descuento <= this.limite) {
            this.message.solicitud = 'Debe indicar valor descuento';
          }
        } else if (this.cotizacion.descuento < this.limite && this.mostrarCamposDescuento == true && this.cotizacion.dirigido != 0 && this.limite > 0) {
          this.toastr.warning("El descuento que desea solicitar debe ser mayor a lo que su usuario tiene permitido","Descuento");
        } else if ( this.cotizacion.descuento <= this.limite  && this.mostrarCamposDescuento == false) {
          this.descuentoValidado = 1;
          this.step = this.step + 1;
        } else if (this.limite == 0 && this.cotizacion.descuento == 0 && this.mostrarBoton == true) {
          this.toastr.warning("El descuento que desea solicitar debe ser mayor a 0","Descuento");
        }

      }
      /* } */

    }
  }

  validarDescuentoAutorizacion(descuen: number) {
    this.message.solicitud = '';
    if (descuen > this.limite && (this.limite > 0 || this.limite == 0)) {
      this.cotizacion.descuento = descuen;
    } else if ((descuen <= this.limite || descuen == 0 || descuen.toString() == '') && this.limite > 0) {
      this.cotizacion.descuento = 0;
    } else if (descuen == 0 && this.limite == 0) {
      this.cotizacion.descuento = 0;
    }
  }

  cancelarSolicitud() {
    this.mostrarCamposDescuento = false;
    this.descuentoEspecial = 0;
    this.cotizacion.descuento = 0;
    this.message.descuento = '';
    this.descuentoAutorizacionEspecial = 0;
  }

  /* onNextStep(): void {
    this.validarPantalla1();
    this.validarPantalla2();
  } */

  onPreviusStep(): void {
    this.step = this.step - 1;
  }

  onSetStep(num: number): void {

  }

  getListTipoVehiculos() {
    this.tipoVehiculoService.getAllTipoVehiculos().subscribe((data: any) => {
      this.tipoVehiculos = data.data;
    }, (error) => {
      console.log('No se cargo los datos de tipos de vehiculos');
    });
  }

  getListMarcas() {
    this.marcaService.getAllMarcas().subscribe((data: any) => {
      this.marcas = data.data;
    }, (error) => {
      console.log('No se cargo los datos de las marcas');
    });
  }

  getListIntermediarios() {
    this.intermediarioService.getAllIntermediarios().subscribe((data: any) => {
      this.intermediarios = data.data;
    }, (error) => {
      console.log('No se cargo los datos de los intermediarios');
    });
  }

  getListDescuentos() {
    this.descuentoService.getAllDescuentos().subscribe((data: any) => {
      this.descuentos = data.data;
    }, (error) => {
      console.log('No se cargo los datos de descuentos');
    });
  }

  cargarDescuento(valor: number) {
    this.descuentoService.getDescuento(valor,this.cotizacion.suma_asegurada).subscribe((data: any) => {
      if (data.length > 0 ){
        this.limite = Number(data.data[0].description);
      }else{
        this.limite = 0;
      }


    }, (error) => {
      console.log('No se logro cargar el limite del descuento');
    });
  }

  cargarDescSuma(valor: number) {
    this.descuentoService.getDescuento(valor,this.cotizacion.suma_asegurada).subscribe((data: any) => {
      //console.log('data.length: ' + data.data.length);
      //console.log('Limite de descuento antes de validar: ' + this.limite);
      if (data.data.length > 0 ){
        this.limite = Number(data.data[0].description);
      }else{
        this.limite = 0;
      }
    }, (error) => {
      console.log('No se logro cargar el limite del descuento');
    });
  }

  cargarValorDescuento(id: number,) {
    if (id == 1) {
      this.cargarDescuento(Number(this.cotizacion.user_id));
      this.estadoDescuento = false;
    } else {
      this.limite = this.descuentos[id - 1].porcentaje;
      this.estadoDescuento = true;
    }
  }

  getListLineas(v1, v2): void {
    this.lineaService.getLineas(v1, v2).subscribe((data: any) => {
      this.lineas = data.data;
    }, (error) => {
      console.log('No se cargaron los datos de las lineas');
    });
  }

  getDataCot(): void {
    this.cotizacionService.getDataCotizacion(this.cotizacion).subscribe((data: any) => {
      this.dataCotizaciones = data.data;
      this.mostrarFormulario = true;
      this.mostrarBoton = false;
    }, (error: any) => {
      this.toastr.error('Verifique los datos ingresados', 'Error');
    });
  }

  getSelectTipo(value: number) {
    this.cotizacion.tipo_vehiculo = value;
    this.message.tipo_vehiculo = '';
    this.message.numero_ocupantes = '';

    if (this.cotizacion.tipo_vehiculo  !== 0 && this.cotizacion.marca  !== 0) {
      this.getListLineas(this.cotizacion.tipo_vehiculo , this.cotizacion.marca );
    }
  }

  getCantOcupantes(value: number) {
    let nAsientos = this.tipoVehiculos.find(tipovehiculo => tipovehiculo.id == value)
    return nAsientos.numero_asientos;
  }

  getSelectMarca(value: number) {
    this.cotizacion.marca  = value;
    this.message.marca = '';
    if (this.cotizacion.tipo_vehiculo  !== 0 && this.cotizacion.marca  !== 0) {
      this.getListLineas(this.cotizacion.tipo_vehiculo, this.cotizacion.marca);
    }

  }

  procesarCotizacion(cotiForm: NgForm) {
    if (this.cotizacion.intermediary_id != '') {
      /* if ((this.cotizacion.descuento > this.limite && this.limite > 0 && this.cotizacion.dirigido > 0) || (this.cotizacion.descuento <= this.limite && this.cotizacion.dirigido == 0)) {
        if (this.cotizacion.descuento == 0 || this.cotizacion.descuento.toString() == '') {
          this.cotizacion.descuento = 0;
          this.message.dirigido = '';
          this.message.solicitud = '';
        }
        this.getDataCot();
      } else if (this.cotizacion.descuento > this.limite && this.limite > 0 && this.cotizacion.dirigido == 0) {
        this.message.dirigido = 'Debe indicar a quien solicitar';
      } else if (this.cotizacion.dirigido != 0 && this.cotizacion.descuento <= this.limite) {
        this.message.solicitud = 'Debe indicar valor descuento';
      } */

      if (this.descuentoValidado == 1 || this.descuentoValidado == 2) {
        this.getDataCot();
      }
    } else {
      this.toastr.warning('El campo intermediario está vacío', 'Sistema');
    }
  }

  agregarLista(value: number) {
    /* const pos = this.planesSeleccionados.indexOf(value);
    if ( pos < 0) {
      this.planesSeleccionados.push(value);
    } else {
      this.planesSeleccionados.splice(pos, pos + 1);
    } */
    if (value == 1 && this.planesSeleccionados[0] > 0) {
      this.planesSeleccionados[0] = 0;
    } else if(value == 1){
      this.planesSeleccionados[0] = value;
    }

    if (value == 2 && this.planesSeleccionados[1] > 0) {
      this.planesSeleccionados[1] = 0;
    } else if(value == 2){
      this.planesSeleccionados[1] = value;
    }

    if (value == 3 && this.planesSeleccionados[2] > 0) {
      this.planesSeleccionados[2] = 0;
    } else if(value == 3){
      this.planesSeleccionados[2] = value;
    }
    /* console.log(pos);
    console.log(this.planesSeleccionados); */
  }

  validacionSuma(cobertura: number, suma: number){
    if (cobertura == 1) {
      this.limiteAnios = 19;
      this.cotizacion.suma_asegurada = suma;
    } else {
      this.limiteAnios = 19;
      alert('Ahora ha cambiado a Solo Responsabilidad Civil hasta 20 años de antigüedad');
      this.cotizacion.suma_asegurada = 1;
    }
  }

  emailIsValid(valor) {
    const emailRegExp = /^[\w]+@{1}[\w]+\.[a-z]{2,3}$/;
    if (emailRegExp.test(valor) ) {
      return true;
    }
    return false;
  }

  validarNumero(valor) {
    const numRegExp = /^\d*(\.\d{1})?\d{0,1}$/;
    if(!isNaN(valor)){
      //console.log('Es numero');
      if ( numRegExp.test(valor) ) {
        return true;
      }
    }

    return false;

  }

  telefonoIsValid(valor: string) {
    const nitRegExp = new RegExp('[0-9]{8}$');
    if ( !nitRegExp.test(valor) ) {
      return true;
    }
  }

  nitIsValid(nit) {
      if (!nit) {
          return true;
      }

      const nitRegExp = new RegExp('^[0-9]+(-?[0-9kK])?$');

      if (!nitRegExp.test(nit)) {
          return false;
      }

      nit = nit.replace(/-/, '');
      const lastChar = nit.length - 1;
      const number1 = nit.substring(0, lastChar);
      const expectedCheker = nit.substring(lastChar, lastChar + 1).toLowerCase();

      let factor = number1.length + 1;
      let total = 0;

      for (let i = 0; i < number1.length; i++) {
        const character = number1.substring(i, i + 1);
        const digit = parseInt(character, 10);

        total += (digit * factor);
        factor = factor - 1;
      }

      const modulus = (11 - (total % 11)) % 11;
      const computedChecker = (modulus === 10 ? 'k' : modulus.toString());

      return expectedCheker === computedChecker;
  }

  procesarInformacion(tipo: string = '') {
    let opcion;
    let sumatoria:number;
    sumatoria = 0;
    this.planesSeleccionados.forEach (function(num:number){
      if (isNaN(num)) {
        num = 0;
      }
      sumatoria += num;
    });

    if (this.cotizacion.dirigido != 0) {

      if (tipo == 'P') {
        if (sumatoria > 0) {
          if(sumatoria == 4 || sumatoria == 6 || (this.planesSeleccionados[0] == 1 && this.planesSeleccionados[1] == 2)) {
            this.toastr.warning('Plan Indiviual o Combinado Plus y Premium', 'Solo puede seleccionar',{
              timeOut: 3000,
            });
            return false;
          }
          this.toastr.info('Espere unos segundos, procesando', 'Información');
          this.dataCotizaciones['datos_final'] = this.cotizacion;
          this.dataCotizaciones['seleccionados'] = this.planesSeleccionados;
          this.cotizacionService.previaPDF(this.dataCotizaciones).subscribe((data: any) => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            /* this.router.navigate(['procesado']); */
          }, (error) => {
            console.log(error);
            this.toastr.error('No pudo procesarse el pdf, verifique que tenga habilitadas las ventanas emergentes en el explorador e inténtelo de nuevo', 'Error');
          });
        } else {
          this.toastr.warning('Debe seleccionar un plan antes de finalizar el proceso', 'Seleccione');
        }
      } else {
        opcion = confirm('¿Desea que se envíe la Solicitud de Autorización?');
      }

      if (opcion == true) {
        if (sumatoria > 0) {
          if(sumatoria == 4 || sumatoria == 6 || (this.planesSeleccionados[0] == 1 && this.planesSeleccionados[1] == 2)) {
            this.toastr.warning('Plan Indiviual o Combinado Plus y Premium', 'Solo puede seleccionar',{
              timeOut: 3000,
            });
            return false;
          }
          this.toastr.info('Espere unos segundos, procesando', 'Información');
          this.dataCotizaciones['datos_final'] = this.cotizacion;
          this.dataCotizaciones['seleccionados'] = this.planesSeleccionados;
          this.dataApiService.postAutorizacion(this.dataCotizaciones).subscribe((data: any) => {
            this.toastr.success('Se ha enviado la solicitud con éxito','Solicitud');
            if (confirm('Si desea crear una nueva cotizacion presione [Aceptar], sino presione [Cancelar] para continuar en la actual. ')) {
              this.router.navigate(['cotizaciones']);
            }
          }, (error) => {
            this.toastr.error('No pudo enviar la solicitud, inténtelo de nuevo', 'Error');
          });
        } else {
          this.toastr.warning('Debe seleccionar un plan antes de finalizar el proceso', 'Seleccione');
        }
      }

    } else {
      if (sumatoria > 0) {
        if(sumatoria == 4 || sumatoria == 6 || (this.planesSeleccionados[0] == 1 && this.planesSeleccionados[1] == 2)) {
          this.toastr.warning('Plan Indiviual o Combinado Plus y Premium', 'Solo puede seleccionar',{
            timeOut: 3000,
          });
          return false;
        }
        this.toastr.info('Espere unos segundos, procesando', 'Información');
        this.dataCotizaciones['datos_final'] = this.cotizacion;
        this.dataCotizaciones['seleccionados'] = this.planesSeleccionados;
        if (tipo == 'P') {
          this.cotizacionService.previaPDF(this.dataCotizaciones).subscribe((data: any) => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            /* this.router.navigate(['procesado']); */
          }, (error) => {
            this.toastr.error('No pudo procesarse el pdf, verifique que tenga habilitadas las ventanas emergentes en el explorador e inténtelo de nuevo', 'Error');
          });
        } else {

          if(sumatoria == 4 || sumatoria == 6 || (this.planesSeleccionados[0] == 1 && this.planesSeleccionados[1] == 2)) {
            this.toastr.warning('Plan Indiviual o Combinado Plus y Premium', 'Solo puede seleccionar',{
              timeOut: 3000,
            });
            return false;
          }
          this.cotizacionService.getPDF(this.dataCotizaciones).subscribe((data: any) => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            /* this.router.navigate(['procesado']); */
          }, (error) => {
            this.toastr.error('No pudo procesarse el pdf, verifique que tenga habilitadas las ventanas emergentes en el explorador e inténtelo de nuevo', 'Error');
          });
        }
      } else {
        this.toastr.warning('Debe seleccionar un plan antes de finalizar el proceso', 'Seleccione');
      }
    }
  }


  filterFunction(datos): any[] {
      return datos.filter(i => i['Tipo Pago'] === 'VISACUOTA');
  }

  filterFunction1(datos): any[] {
    return datos.filter(i => i['Tipo Pago'] === 'NORMAL');
  }

  filterFunction2(datos): any[] {
    return datos.filter(i => i.user_id !== this.cotizacion.user_id);
  }

  filterFunctionValorDescuento(datos, id): number {
    let valor = datos.filter(i => i.user_id == id);
    return parseInt(valor[0].description);
  }

  setPagos(valores) {
    this.dataPagos = valores;
  }

  getHabilitarDescuento(){
    this.habilitarDescuento = false;
    if ((this.anio - this.limiteAntiguedad) > this.cotizacion.modelo) {
      this.habilitarDescuento = true;
      this.cotizacion.descuento = 0;
    } else if(this.cotizacion.uber == 1){
      this.habilitarDescuento = true;
      this.cotizacion.descuento = 0;
    } else if(this.cotizacion.cobertura == 0){
      this.habilitarDescuento = true;
      this.cotizacion.descuento = 0;
    }
  }

  getHabilitarValPactado(){
    //debugger;
    this.habilitarValPactado = false;
    /*
    this.habilitarValPactado = false;
    this.cotizacion.valor_pactado = 1;
    this.valor_pactadoElement.nativeElement.value = 1;
    //Antieguedad del vehiculo
    if ((this.anio - this.limiteAntiguedad) > this.cotizacion.modelo) {
      this.habilitarValPactado = true;
      this.cotizacion.valor_pactado = 0;
      this.valor_pactadoElement.nativeElement.value = 0;
    }
    //Si es Uber
    else if(this.cotizacion.uber == 1){
      this.habilitarValPactado = true;
      this.cotizacion.valor_pactado = 0;
      this.valor_pactadoElement.nativeElement.value = 0;
    }
    //Si selecciono solo Responsabilidad Civil
    else if(this.cotizacion.cobertura == 0){
      this.habilitarValPactado = true;
      this.cotizacion.valor_pactado = 0;
      this.valor_pactadoElement.nativeElement.value = 0;
    }
    //Si el tipo de vehiculo es camion
    else if((this.cotizacion.tipo_vehiculo == 10)||(this.cotizacion.tipo_vehiculo == 11)){
      this.habilitarValPactado = true;
      this.cotizacion.valor_pactado = 0;
      this.valor_pactadoElement.nativeElement.value = 0;
    }
    */
  }

  /* removeItemFromArr( arr, item ) {
    let i = arr.indexOf( item );

    if ( i !== -1 ) {
        arr.splice( i, 1 );
    }
  } */


}
