import { ToastrService } from 'ngx-toastr';
import { User } from './../../interfaces/user';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private authService: AuthService, private toastrService: ToastrService, private router: Router) { }

  public appName = 'Cotizador';

  public tempNombreAvatar = '';
  public valorAvatar: any;

  public baseImagenes: string;
  public urlImagenes: string;

  public isLogged = false;

  public user: User = {
    name: null,
    email: null,
    password: null,
    avatar: null,
    remember_me: false
  };

  ngOnInit() {
    this.baseImagenes = 'http://192.168.0.50:8002/avatars/';
    this.urlImagenes = this.baseImagenes + 'user.jpg';
    this.onCheckUser();
  }

  logout() {
    const token = this.authService.getToken();
    this.authService.salirSistema(token).subscribe((data) => {
      console.log('A salido del sistema, token revocado');
      setTimeout(() => {
        window.location.href = 'http://cotizador.aseguate.com/auto/';
        /* window.location.href = 'http://192.168.0.52:4202'; */
      }, 500);
    });
    localStorage.removeItem('accessToken');
    this.isLogged = false;
    this.router.navigate(['/user/login']);
  }

  onCheckUser(): void {
    const token = this.authService.getToken();
    if (this.authService.getToken() == null) {
      this.isLogged = false;
    } else {
      this.isLogged = true;
      this.authService.getUser(token).subscribe((info: User) => {
        this.user = info;
        this.urlImagenes = this.baseImagenes + this.user.avatar;
        if (this.user.id == 455) {
          this.logout();
        }
      });
    }
  }

  actualizarFoto() {
    if (this.tempNombreAvatar == '') {
      this.toastrService.warning('Debe seleccionar una imagen antes de actualizar su foto', 'Error de envío');
    } else {
      const token = this.authService.getToken();
      this.toastrService.info('Actualizando Foto', 'Procesando');
      const formData = new FormData();
      formData.append('avatar', this.valorAvatar);

      this.authService.postCambioAvatar(formData, this.user.id, token).subscribe((data: any) => {
        this.user.avatar = data.data.avatar;
        this.urlImagenes = this.baseImagenes + this.user.avatar;
        this.tempNombreAvatar = '';
        this.valorAvatar = '';
        this.toastrService.success('Foto Actualizada', 'Actualización Exitosa');
      }, (error) => {
        this.toastrService.error('La imagen no pudo ser actualizada', 'Error proceso');
      });
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.valorAvatar = event.target.files[0];
    }
  }

}
