import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procesado',
  templateUrl: './procesado.component.html',
  styleUrls: ['./procesado.component.css']
})
export class ProcesadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
