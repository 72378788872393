import { AuthorizeComponent } from './components/user/authorize/authorize.component';
import { ListadoCotizacionesComponent } from './components/listado-cotizaciones/listado-cotizaciones.component';
import { ResetComponent } from './components/user/reset/reset.component';
import { ProcesadoComponent } from './components/procesado/procesado.component';
import { CotizacionComponent } from './components/cotizacion/cotizacion.component';
import { CotizacionesComponent } from './components/cotizaciones/cotizaciones.component';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/user/login/login.component';
import { RegisterComponent } from './components/user/register/register.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { Page404Component } from './components/page404/page404.component';

const routes: Routes = [
  {path: '', component: CotizacionComponent, canActivate: [ AuthGuard ]}, // TODO: only users auth
  {path: 'cotizaciones', component: CotizacionesComponent, canActivate: [ AuthGuard ]}, // TODO: only users auth
  {path: 'cotizacion', component: CotizacionComponent, canActivate: [ AuthGuard ]}, // TODO: only users auth
  {path: 'user/login', component: LoginComponent},
  {path: 'reset_password', component: AuthorizeComponent},
  {path: 'listado-cotizaciones', component: ListadoCotizacionesComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'procesado', component: ProcesadoComponent},
  {path: 'user/register', component: RegisterComponent, canActivate: [ AuthGuard ]}, // TODO: only users auth
  {path: 'user/profile', component: ProfileComponent, canActivate: [ AuthGuard ]}, // TODO: only users auth
  {path: '**', component: Page404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
