import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  encabezado: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.authService.getToken()
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  postEmail(email: any) {
    return this.httpClient.post(this.apiUrl + 'forgot_password', email, { headers: this.headers });
  }

  postReset(password: any) {
    return this.httpClient.post('http://186.189.199.114:50002/api/' +  'reset_password', password, { headers: this.headers });
  }

  postAutorizacion(datos: any) {
    return this.httpClient.post(this.apiUrl + 'autorizacion', datos, { headers: this.encabezado });
  }
}
