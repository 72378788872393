import { ToastrService } from 'ngx-toastr';
import { CotizacionService } from './../../services/cotizacion.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-listado-cotizaciones',
  templateUrl: './listado-cotizaciones.component.html',
  styleUrls: ['./listado-cotizaciones.component.css']
})

export class ListadoCotizacionesComponent implements OnInit, AfterViewInit, OnDestroy  {

  usuario: number;
  solicitudes = [];
  autorizadas = [];
  asignadas = [];

  datosImpresion: any = [];

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject;
  dtTrigger1: Subject<any> = new Subject;
  dtTrigger2: Subject<any> = new Subject;

  es: any = {
      sProcessing:     'Procesando...',
      sLengthMenu:     'Mostrar _MENU_ registros',
      sZeroRecords:    'No se encontraron resultados',
      sEmptyTable:     'Ningún dato disponible en esta tabla',
      sInfo:           'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
      sInfoEmpty:      'Mostrando registros del 0 al 0 de un total de 0 registros',
      sInfoFiltered:   '(filtrado de un total de _MAX_ registros)',
      sInfoPostFix:    '',
      sSearch:         'Buscar:',
      sUrl:            '',
      sInfoThousands:  ',',
      sLoadingRecords: 'Cargando...',
      oPaginate: {
          sFirst:    'Primero',
          sLast:     'Último',
          sNext:     'Siguiente',
          sPrevious: 'Anterior'
      },
      oAria: {
          sSortAscending:  ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
      },
      buttons: {
          copy: 'Copiar',
          colvis: 'Visibilidad'
      }
  }

  constructor(private cotizacionService: CotizacionService, private toastr: ToastrService) { }

  ngOnInit() {
    this.cargarInformacion();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: this.es
    };
  }

  getEstado(valor) {
    if ( valor == 1 ) {
      return 'Activo';
    } else if ( valor == 2 ) {
      return 'Autorizado';
    } else if ( valor == 3 ) {
      return 'Denegado';
    } else if ( valor == 4 ) {
      return 'Eliminado';
    } else if ( valor == 5 ) {
      return 'Procesado';
    }
    return '';
  }

  getEstadoEstilo(valor) {
    if ( valor == 1 ) {
      return 'badge-info';
    } else if ( valor == 2 ) {
      return 'badge-success';
    } else if ( valor == 3 ) {
      return 'badge-danger';
    } else if ( valor == 4 ) {
      return 'badge-secondary';
    } else if ( valor == 5 ) {
      return 'badge-primary';
    }
    return '';
  }

  eliminar(dato: any) {
    dato.estado = 4;
    this.cotizacionService.putCotizacion(dato).subscribe((data: any) => {
      this.cargarInformacion('P');
      this.toastr.success('Se eliminó correctamente', 'Proceso Exitoso');
    }, (error) => {
      dato.estado = 1;
      this.toastr.error('No se logró eliminar el registro', 'Error Sistema');
    });
  }

  aceptar(dato: any) {
    dato.estado = 2;
    this.cotizacionService.putAceptacion(dato).subscribe((data: any) => {
      this.cargarInformacion('P');
      this.toastr.success('Se envió notificación', 'Proceso Exitoso');
    }, (error) => {
      dato.estado = 1;
      this.toastr.error('No se logró enviar la notificación', 'Error Sistema');
    });
  }

  cancelar(dato: any) {
    dato.estado = 3;
    this.cotizacionService.putCancelacion(dato).subscribe((data: any) => {
      this.cargarInformacion('P');
      this.toastr.info('Se envió notificación de cancelacion', 'Proceso Exitoso');
    }, (error) => {
      dato.estado = 1;
      this.toastr.error('No se logró enviar la notificación de cancelación', 'Error Sistema');
    });
  }

  cargarInformacion(dato: string = '') {
    this.cotizacionService.getUser().subscribe((data: any) => {
      this.usuario = Number(data.id);
      this.cotizacionService.getListActivas(this.usuario).subscribe((info: any) => {
        this.solicitudes = info.data;
        this.rerender();
      });

      this.cotizacionService.getListAutorizadas(this.usuario).subscribe((info: any) => {
        this.autorizadas = info.data;
        this.rerender1();
      });

      this.cotizacionService.getListAsignadas(this.usuario).subscribe((info: any) => {
        this.asignadas = info.data;
        this.rerender2();
      });

    });
  }

  imprimirCotizacion(dato) {
    try {

      this.toastr.info('Espere unos segundos, procesando', 'Información');

      this.cotizacionService.reprintPDF({datos_final: JSON.parse(dato.datos_final), detalle: JSON.parse(dato.detalle), encabezado: JSON.parse(dato.encabezado), seleccionados: JSON.parse(dato.seleccionados), created_at: dato.created_at, id: dato.id }).subscribe((data: any) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        /* this.router.navigate(['procesado']); */
      }, (error) => {
        this.toastr.error('No pudo procesarse el pdf, inténtelo de nuevo', 'Error Sistema');
      });

    } catch(e) {
      this.toastr.error('Los datos estan corruptos para ser procesados', 'Error Sistema');
    }
  }

  previewCotizacion(dato) {
    try {

      this.toastr.info('Espere unos segundos, procesando', 'Información');

      this.cotizacionService.previewPDF({datos_final: JSON.parse(dato.datos_final), detalle: JSON.parse(dato.detalle), encabezado: JSON.parse(dato.encabezado), seleccionados: JSON.parse(dato.seleccionados) }).subscribe((data: any) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        /* this.router.navigate(['procesado']); */
      }, (error) => {
        this.toastr.error('No pudo procesarse el pdf, inténtelo de nuevo', 'Error Sistema');
      });

    } catch(e) {
      this.toastr.error('Los datos estan corruptos para ser procesados', 'Error Sistema');
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dtTrigger1.next();
    this.dtTrigger2.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  getCliente(dato): string {
    try {
      if (dato.apellido != undefined) {
        return dato.nombre + ' ' + dato.apellido;
      } else {
        return dato.nombre;
      }

    } catch (error) {
      return '';
    }
    return '';
  }

  rerender(): void {
    this.dtElements.first.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  rerender1(): void {
    const elementTwo = this.dtElements.filter((element, index) => index === 1);
    elementTwo[0].dtInstance.then((dtInstance1: DataTables.Api) => {
      // Destroy the table first
      dtInstance1.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger1.next();
    });
  }

  rerender2(): void {
    this.dtElements.last.dtInstance.then((dtInstance2: DataTables.Api) => {
      // Destroy the table first
      dtInstance2.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger2.next();
    });
  }

}
