import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {DataTablesModule} from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegisterComponent } from './components/user/register/register.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { Page404Component } from './components/page404/page404.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CotizacionesComponent } from './components/cotizaciones/cotizaciones.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CotizacionComponent } from './components/cotizacion/cotizacion.component';
import { ProcesadoComponent } from './components/procesado/procesado.component';
import { AuthorizeComponent } from './components/user/authorize/authorize.component';
import { ResetComponent } from './components/user/reset/reset.component';
import { ListadoCotizacionesComponent } from './components/listado-cotizaciones/listado-cotizaciones.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    Page404Component,
    NavbarComponent,
    CotizacionesComponent,
    CotizacionComponent,
    ProcesadoComponent,
    AuthorizeComponent,
    ResetComponent,
    ListadoCotizacionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    DataTablesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
