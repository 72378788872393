import { DataApiService } from './../../../services/data-api.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit, OnDestroy {

  public message = {
    email: ''
  };

  formulario: any = {
    email: ''
  };

  constructor(private dataApiService: DataApiService, private toastr: ToastrService) {
    document.body.style.backgroundColor ="rgb(19, 34, 86)";
   }

   ngOnDestroy() {
    document.body.style.backgroundColor ="rgb(255, 255, 255)";
  }

  ngOnInit() {
  }

  emailIsValid(valor) {
    const emailRegExp = /^[\w]+@{1}[\w]$/;
    if (emailRegExp.test(valor) ) {
      return true;
    }
    return false;
  }

  onSendEmail() {
    if (this.formulario.email === '') {
      this.message.email = 'Ingrese un email válido';
    } else if (this.emailIsValid(this.formulario.email)) {
      this.message.email = 'Ingrese un email válido';
    } else {
      this.message.email = '';
      this.dataApiService.postEmail(this.formulario).subscribe( (data: any) => {
        if (data.status == true) {
          this.toastr.success('Revise su bandeja de correo', 'Correo Enviado');
        } else {
          this.toastr.error('Correo no registrado en el sistema', 'Correo No Enviado');
        }
      }, (error) => {
        this.toastr.error('Ha ocurrido un problema. Inténtelo mas tarde', 'Error de Sistema');
      });
    }
  }

}
