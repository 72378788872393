import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.authService.getToken()
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getDataCotizacion(datos: any) {
    return this.httpClient.post(this.apiUrl + 'cotizaciones/crear', datos, { headers: this.headers });
  }

  getPDF(datos: any) {
    return this.httpClient.post(this.apiUrl + 'pdf', datos, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  getUser() {
    return this.httpClient.get(this.apiUrl + 'auth/user', { headers: this.headers });
  }

  getMyUser(id: any) {
    return this.httpClient.get(this.apiUrl + 'user/' + id, { headers: this.headers });
  }

  getUsers() {
    return this.httpClient.get(this.apiUrl + 'usuarios', { headers: this.headers });
  }

  getLimiteAnios() {
    return this.httpClient.get(this.apiUrl + 'limite', { headers: this.headers });
  }

  getLimiteAntiguedad() {
    return this.httpClient.get(this.apiUrl + 'antiguedad', { headers: this.headers });
  }

  getListActivas(user: number) {
    return this.httpClient.get(this.apiUrl + 'cotizaciones/' + user, { headers: this.headers });
  }

  getListAutorizadas(user: number) {
    return this.httpClient.get(this.apiUrl + 'autorizadas/' + user, { headers: this.headers });
  }

  getListAsignadas(user: number) {
    return this.httpClient.get(this.apiUrl + 'asignadas/' + user, { headers: this.headers });
  }

  putCotizacion(cotiz: any) {
    return this.httpClient.put(this.apiUrl + 'cambio_estado/' + cotiz.id, cotiz, { headers: this.headers });
  }

  putAceptacion(cotiz: any) {
    return this.httpClient.put(this.apiUrl + 'aceptado', cotiz, { headers: this.headers });
  }

  putCancelacion(cotiz: any) {
    return this.httpClient.put(this.apiUrl + 'cancelado', cotiz, { headers: this.headers });
  }

  previewPDF(datos: any) {
    return this.httpClient.post(this.apiUrl + 'preview', datos, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  previaPDF(datos: any) {
    return this.httpClient.post(this.apiUrl + 'previa', datos, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  reprintPDF(datos: any) {
    return this.httpClient.post(this.apiUrl + 'reprint', datos, { headers: this.headers, responseType: 'blob' as 'json' });
  }

}

