import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  public userData: User = {
    name: null,
    email: null,
    password: null,
    avatar: null,
    remember_me: false
  };

  constructor(private httpClient: HttpClient) { }


  /* registerUser(name: string, email: string, password: string) {
    return this.httpClient.post(this.apiUrl + 'users', {
      name: name,
      email: email,
      password: password
    },
    { headers: this.headers }
    );
  } */


  loginUser(user: User) {
    return this.httpClient.post(this.apiUrl + 'auth/login', user, { headers: this.headers });
  }

  setToken(token): void {
    localStorage.setItem('accessToken', token);
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  getUser(token) {
    return this.httpClient.get(this.apiUrl + 'auth/user', { headers: {
      Authorization: 'Bearer ' + token
    } });
  }

  postCambioAvatar(foto: any, user, token) {
    return this.httpClient.post(this.apiUrl + 'avatar/' + user , foto, { headers: {
      Authorization: 'Bearer ' + token
    } });
  }

  salirSistema(token) {
    return this.httpClient.get(this.apiUrl + 'auth/logout', { headers:  {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    } });
  }


}
