import { environment } from './../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DescuentoService {

  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.authService.getToken()
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getAllDescuentos() {
    return this.httpClient.get(this.apiUrl + 'descuentos', { headers: this.headers });
  }

  getDescuento(id: number, suma: number) {
    return this.httpClient.get(this.apiUrl + 'roles/' + id + '/'+ suma, { headers: this.headers});
  }
}
