import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntermediarioService {

  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.authService.getToken()
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getAllIntermediarios() {
    return this.httpClient.get(this.apiUrl + 'intermediarios', { headers: this.headers });
  }
}
