import { environment } from './../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TipoVehiculoService {

  apiUrl = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.authService.getToken()
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getAllTipoVehiculos() {
    return this.httpClient.get(this.apiUrl + 'tipo_vehiculos', { headers: this.headers });
  }

}
