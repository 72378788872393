import { ToastrService } from 'ngx-toastr';
import { DataApiService } from './../../../services/data-api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit, OnDestroy {

  public message = {
    r_password: '',
    r_password_confirmation: ''
  };

  dataReset  = {
    token: '',
    password: '',
    password_confirmation: ''
  };

  constructor(private activateRoute: ActivatedRoute, private router: Router, private dataApiService: DataApiService, private toastr: ToastrService) {
    document.body.style.backgroundColor ="rgb(19, 34, 86)";
   }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      this.dataReset.token = params.token;

    });
  }

  ngOnDestroy() {
    document.body.style.backgroundColor ="rgb(255, 255, 255)";
  }

  resetPassword() {
      this.message.r_password_confirmation = '';
      this.message.r_password = '';
      if (this.dataReset.password.length === 0 || this.dataReset.password.length < 6) {
        this.message.r_password = 'Debe ingresar la contraseña (Mínimo 6 caracteres)';
        this.toastr.warning(this.message.r_password, 'Valide su contraseña');
      } else if (this.dataReset.password_confirmation.length === 0 || this.dataReset.password_confirmation.length < 6) {
        this.message.r_password_confirmation = 'Debe ingresar la contraseña (Mínimo 6 caracteres)';
        this.toastr.warning(this.message.r_password_confirmation, 'Valide su contraseña');
      } else if (this.dataReset.password !== this.dataReset.password_confirmation) {
        this.message.r_password_confirmation = 'Las contraseñas no coinciden';
        this.message.r_password = 'Las contraseñas no coinciden';
        this.toastr.warning(this.message.r_password_confirmation, 'Valide su contraseña');
      } else {
        this.message.r_password_confirmation = '';
        this.message.r_password = '';
        this.dataApiService.postReset(this.dataReset).subscribe((data: any) => {
          if (data.status == true) {
            this.toastr.success('Ingrese con su nueva contraseña', 'Proceso Exitoso');
            setTimeout(() => {
              window.location.href = 'http://cotizador.aseguate.com/auto/';
              /* window.location.href = 'http://192.168.101.7:4200'; */
            }, 500);
          } else {
            this.toastr.error('El token ya no es válido. ', 'Proceso Cancelado');
          }
        }, (error) => {
          this.toastr.error('Ha ocurrido un problema. Inténtelo mas tarde', 'Error de Sistema');
        });
      }
  }

}
